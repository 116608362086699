import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {MaterialModule} from "./shared/material/material.module";
import {AuthModule} from "./auth/auth.module";
import {PublicModule} from "./public/public.module";
import {SecureModule} from "./secure/secure.module";
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {CredentialInterceptor} from "./shared/interceptors/credential.interceptor";
import {AuthService} from "./shared/services/auth.service";
import {SnackbarService} from "./shared/services/snackbar.service";
import {ServiceOrdersService} from "./shared/services/service-orders.service";
import {ChatMessageService} from "./shared/services/chat-message.service";
import {WorkingTimeService} from "./shared/services/working-time.service";
import {DATE_PIPE_DEFAULT_OPTIONS} from "@angular/common";
import {WaterProtocolService} from "./shared/services/water-protocol.service";
import {WaterMeterService} from "./shared/services/water-meter.service";
import {ImagesService} from "./shared/services/images.service";
import {IntercomCodesService} from "./shared/services/intercom-codes.service";

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MaterialModule,
        PublicModule,
        AuthModule,
        SecureModule
    ],
    providers: [
        provideAnimationsAsync(),
        provideHttpClient(),
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CredentialInterceptor,
            multi: true,
        },
        {provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: {timezone: 'UTC'}},
        AuthService,
        SnackbarService,
        ServiceOrdersService,
        ChatMessageService,
        WorkingTimeService,
        WaterProtocolService,
        WaterMeterService,
        ImagesService,
        IntercomCodesService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
